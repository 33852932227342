import { create, fromJson, toJson } from '@bufbuild/protobuf';
import {
  createConnectQueryKey,
  createProtobufSafeUpdater,
  createQueryOptions,
  useMutation as useConnectMutation,
  useQuery as useConnectQuery,
  useTransport,
} from '@connectrpc/connect-query';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Array, pipe } from 'effect';
import { Ulid } from 'id128';
import { useCallback, useMemo } from 'react';
import { Collection, Dialog, DialogTrigger, Tab, TabList, TabPanel, Tabs } from 'react-aria-components';
import { useFieldArray, useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { LuTrash2 } from 'react-icons/lu';
import { twJoin } from 'tailwind-merge';

import { useSpecMutation } from '@the-dev-tools/api/query';
import { environmentCreateSpec } from '@the-dev-tools/api/spec/environment';
import { workspaceUpdateSpec } from '@the-dev-tools/api/spec/workspace';
import { Environment } from '@the-dev-tools/spec/environment/v1/environment_pb';
import { environmentList } from '@the-dev-tools/spec/environment/v1/environment-EnvironmentService_connectquery';
import {
  VariableCreateResponseSchema,
  VariableJson,
  VariableListItem,
  VariableListItemSchema,
  VariableListResponseSchema,
  VariableSchema,
  VariableUpdateRequestSchema,
} from '@the-dev-tools/spec/variable/v1/variable_pb';
import {
  variableCreate,
  variableDelete,
  variableList,
  variableUpdate,
} from '@the-dev-tools/spec/variable/v1/variable-VariableService_connectquery';
import { workspaceGet } from '@the-dev-tools/spec/workspace/v1/workspace-WorkspaceService_connectquery';
import { Button } from '@the-dev-tools/ui/button';
import { CheckboxRHF } from '@the-dev-tools/ui/checkbox';
import { DataTable } from '@the-dev-tools/ui/data-table';
import { GlobalEnvironmentIcon, VariableIcon } from '@the-dev-tools/ui/icons';
import { ListBoxItem } from '@the-dev-tools/ui/list-box';
import { Modal } from '@the-dev-tools/ui/modal';
import { Select } from '@the-dev-tools/ui/select';
import { tw } from '@the-dev-tools/ui/tailwind-literal';
import { TextFieldRHF } from '@the-dev-tools/ui/text-field';

import { HidePlaceholderCell, useFormTableSync } from './form-table';

const workspaceRoute = getRouteApi('/_authorized/workspace/$workspaceIdCan');

export const EnvironmentsWidget = () => {
  const { workspaceId } = workspaceRoute.useLoaderData();

  const workspaceGetQuery = useConnectQuery(workspaceGet, { workspaceId });
  const workspaceUpdateMutation = useSpecMutation(workspaceUpdateSpec);

  const environmentListQuery = useConnectQuery(environmentList, { workspaceId });
  const environmentCreateMutation = useSpecMutation(environmentCreateSpec);

  if (!environmentListQuery.isSuccess || !workspaceGetQuery.isSuccess) return null;

  const environments = environmentListQuery.data.items;
  const { selectedEnvironmentId } = workspaceGetQuery.data;
  const selectedEnvironmentIdCan = Ulid.construct(selectedEnvironmentId).toCanonical();

  return (
    <div className={tw`flex justify-between border-b border-slate-200 p-3`}>
      <Select
        aria-label='Environment'
        selectedKey={selectedEnvironmentIdCan}
        onSelectionChange={(selectedEnvironmentIdCan) => {
          const selectedEnvironmentId = Ulid.fromCanonical(selectedEnvironmentIdCan as string).bytes;
          workspaceUpdateMutation.mutate({ workspaceId, selectedEnvironmentId });
        }}
        triggerClassName={tw`justify-start p-0`}
        triggerVariant='ghost'
        listBoxItems={environments}
      >
        {(item) => {
          const environmentIdCan = Ulid.construct(item.environmentId).toCanonical();
          return (
            <ListBoxItem id={environmentIdCan} textValue={item.name}>
              <div className={tw`flex items-center gap-2`}>
                <div
                  className={tw`flex size-6 items-center justify-center rounded-md bg-slate-200 text-xs text-slate-500`}
                >
                  {item.isGlobal ? <VariableIcon /> : item.name[0]}
                </div>
                <span className={tw`text-md font-semibold leading-5 tracking-tight text-slate-800`}>
                  {item.isGlobal ? 'Global Environment' : item.name}
                </span>
              </div>
            </ListBoxItem>
          );
        }}
      </Select>

      <DialogTrigger>
        <Button variant='ghost' className={tw`p-1`}>
          <GlobalEnvironmentIcon className={tw`size-4 text-slate-500`} />
        </Button>

        <Modal modalClassName={tw`size-full`}>
          <Dialog className={tw`h-full outline-none`}>
            {({ close }) => (
              <Tabs className={tw`flex h-full`}>
                <div className={tw`flex w-64 flex-col border-r border-slate-200 bg-slate-50 p-4 tracking-tight`}>
                  <div className={tw`-order-3 mb-4`}>
                    <div className={tw`mb-0.5 text-sm font-semibold leading-5 text-slate-800`}>Variable Settings</div>
                    <div className={tw`text-xs leading-4 text-slate-500`}>Manage variables & environment</div>
                  </div>

                  <div className={tw`-order-1 mb-1 mt-3 flex items-center justify-between py-0.5`}>
                    <span className={tw`text-md leading-5 text-slate-400`}>Environments</span>

                    <Button
                      variant='ghost'
                      className={tw`bg-slate-200 p-0.5`}
                      onPress={() => void environmentCreateMutation.mutate({ workspaceId, name: 'New Environment' })}
                    >
                      <FiPlus className={tw`size-4 text-slate-500`} />
                    </Button>
                  </div>

                  <TabList className={tw`contents`} items={environments}>
                    {(item) => {
                      const environmentIdCan = Ulid.construct(item.environmentId).toCanonical();
                      return (
                        <Tab
                          id={environmentIdCan}
                          className={({ isSelected }) =>
                            twJoin(
                              tw`-mx-2 flex cursor-pointer items-center gap-1.5 rounded-md px-3 py-1.5 text-sm`,
                              isSelected && tw`bg-slate-200`,
                              item.isGlobal && tw`-order-2`,
                            )
                          }
                        >
                          {item.isGlobal ? (
                            <VariableIcon className={tw`size-4 text-slate-500`} />
                          ) : (
                            <div
                              className={tw`flex size-4 items-center justify-center rounded bg-slate-300 text-xs leading-3 text-slate-500`}
                            >
                              {item.name[0]}
                            </div>
                          )}
                          <span className={tw`text-md font-semibold leading-5`}>
                            {item.isGlobal ? 'Global Variables' : item.name}
                          </span>
                        </Tab>
                      );
                    }}
                  </TabList>
                </div>

                <Collection items={environments}>
                  {(item) => {
                    const environmentIdCan = Ulid.construct(item.environmentId).toCanonical();
                    return (
                      <TabPanel id={environmentIdCan} className={tw`flex h-full min-w-0 flex-1 flex-col`}>
                        <div className={tw`px-6 py-4`}>
                          <div className={tw`mb-4 flex items-center gap-2`}>
                            {item.isGlobal ? (
                              <VariableIcon className={tw`size-6 text-slate-500`} />
                            ) : (
                              <div
                                className={tw`flex size-6 items-center justify-center rounded-md bg-slate-300 text-xs leading-3 text-slate-500`}
                              >
                                {item.name[0]}
                              </div>
                            )}
                            <h1 className={tw`font-semibold leading-5 tracking-tight text-slate-800`}>
                              {item.isGlobal ? 'Global Variables' : item.name}
                            </h1>
                          </div>

                          <VariablesTableLoader environmentId={item.environmentId} />
                        </div>

                        <div className={tw`flex-1`} />

                        <div className={tw`flex justify-end gap-2 border-t border-slate-200 px-6 py-3`}>
                          {/* TODO: implement cancel (undo) */}
                          <Button onPress={close}>Cancel</Button>
                          <Button variant='primary' onPress={close}>
                            Save
                          </Button>
                        </div>
                      </TabPanel>
                    );
                  }}
                </Collection>
              </Tabs>
            )}
          </Dialog>
        </Modal>
      </DialogTrigger>
    </div>
  );
};

interface VariablesTableLoaderProps {
  environmentId: Environment['environmentId'];
}

const VariablesTableLoader = ({ environmentId }: VariablesTableLoaderProps) => {
  const variableListQuery = useConnectQuery(variableList, { environmentId });
  if (!variableListQuery.isSuccess) return;
  return <VariablesTable environmentId={environmentId} items={variableListQuery.data.items} />;
};

interface VariablesTableProps extends VariablesTableLoaderProps {
  items: VariableListItem[];
}

const VariablesTable = ({ environmentId, items }: VariablesTableProps) => {
  const queryClient = useQueryClient();
  const transport = useTransport();

  const { workspaceId } = workspaceRoute.useLoaderData();

  const variableCreateMutation = useConnectMutation(variableCreate);
  const variableUpdateMutation = useConnectMutation(variableUpdate);
  const { mutate: variableDeleteMutate } = useConnectMutation(variableDelete);

  const makeItem = useCallback(
    (variableId?: string, item?: VariableJson) => ({ ...item, variableId: variableId ?? '', enabled: true }),
    [],
  );
  const values = useMemo(
    () => ({ items: [...items.map((_): VariableJson => toJson(VariableListItemSchema, _)), makeItem()] }),
    [items, makeItem],
  );
  const { getValues, ...form } = useForm({ values });
  const { remove: removeField, ...fieldArray } = useFieldArray({
    control: form.control,
    name: 'items',
    keyName: 'variableId',
  });

  const onChange = useCallback(
    () => queryClient.invalidateQueries(createQueryOptions(variableList, { workspaceId }, { transport })),
    [queryClient, transport, workspaceId],
  );

  const columns = useMemo(() => {
    const { accessor, display } = createColumnHelper<VariableJson>();
    return [
      accessor('enabled', {
        header: '',
        size: 0,
        cell: ({ row, table }) => (
          <HidePlaceholderCell row={row} table={table} className={tw`flex justify-center`}>
            <CheckboxRHF control={form.control} name={`items.${row.index}.enabled`} variant='table-cell' />
          </HidePlaceholderCell>
        ),
      }),
      accessor('name', {
        meta: { divider: false },
        cell: ({ row }) => (
          <TextFieldRHF control={form.control} name={`items.${row.index}.name`} variant='table-cell' />
        ),
      }),
      accessor('value', {
        cell: ({ row: { index } }) => (
          <TextFieldRHF control={form.control} name={`items.${index}.value`} variant='table-cell' />
        ),
      }),
      accessor('description', {
        cell: ({ row }) => (
          <TextFieldRHF control={form.control} name={`items.${row.index}.description`} variant='table-cell' />
        ),
      }),
      display({
        id: 'actions',
        header: '',
        size: 0,
        meta: { divider: false },
        cell: ({ row, table }) => (
          <HidePlaceholderCell row={row} table={table}>
            <Button
              className={tw`text-red-700`}
              variant='ghost'
              onPress={() => {
                const variableIdJson = getValues(`items.${row.index}.variableId`);
                if (variableIdJson === undefined) return;
                const { variableId } = fromJson(VariableSchema, { variableId: variableIdJson });
                variableDeleteMutate({ variableId });
                removeField(row.index);
                void onChange();
              }}
            >
              <LuTrash2 />
            </Button>
          </HidePlaceholderCell>
        ),
      }),
    ];
  }, [form.control, variableDeleteMutate, getValues, removeField, onChange]);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getRowId: (_) => _.variableId ?? '',
    defaultColumn: { minSize: 0 },
    data: fieldArray.fields,
    columns,
  });

  const setData = useCallback(async () => {
    await onChange();
    const items = pipe(
      getValues('items'),
      Array.dropRight(1),
      Array.map((_) => fromJson(VariableListItemSchema, _)),
    );
    queryClient.setQueryData(
      createConnectQueryKey({ schema: variableList, cardinality: 'finite', input: { workspaceId }, transport }),
      createProtobufSafeUpdater(variableList, () => create(VariableListResponseSchema, { items })),
    );
  }, [getValues, onChange, queryClient, transport, workspaceId]);

  useFormTableSync({
    field: 'items',
    form: { ...form, getValues },
    fieldArray,
    makeItem,
    getRowId: (_) => _.variableId,
    onCreate: async (variable) => {
      const response = await variableCreateMutation.mutateAsync({ ...variable, environmentId });
      return toJson(VariableCreateResponseSchema, response).variableId ?? '';
    },
    onUpdate: (variable) => variableUpdateMutation.mutateAsync(fromJson(VariableUpdateRequestSchema, variable)),
    onChange,
    setData,
  });

  return <DataTable table={table} />;
};
