/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './root';
import { Route as loginImport } from './login';
import { Route as authorizedImport } from './authorized';
import { Route as dashboardImport } from './dashboard';
import { Route as workspaceLayoutImport } from './workspace-layout';
import { Route as workspaceListImport } from './workspace-list';
import { Route as workspaceMembersImport } from './workspace-members';
import { Route as endpointImport } from './endpoint';
import { Route as headersImport } from './headers';
import { Route as bodyImport } from './body';
import { Route as assertionsImport } from './assertions';
import { Route as queryImport } from './query';

// Create/Update Routes

const loginRoute = loginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any);

const authorizedRoute = authorizedImport.update({
  id: '/_authorized',
  getParentRoute: () => rootRoute,
} as any);

const dashboardRoute = dashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => authorizedRoute,
} as any);

const workspaceLayoutRoute = workspaceLayoutImport.update({
  id: '/workspace/$workspaceIdCan',
  path: '/workspace/$workspaceIdCan',
  getParentRoute: () => authorizedRoute,
} as any);

const workspaceListRoute = workspaceListImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => dashboardRoute,
} as any);

const workspaceMembersRoute = workspaceMembersImport.update({
  id: '/members',
  path: '/members',
  getParentRoute: () => workspaceLayoutRoute,
} as any);

const endpointRoute = endpointImport.update({
  id: '/endpoint/$endpointIdCan/example/$exampleIdCan',
  path: '/endpoint/$endpointIdCan/example/$exampleIdCan',
  getParentRoute: () => workspaceLayoutRoute,
} as any);

const headersRoute = headersImport.update({
  id: '/headers',
  path: '/headers',
  getParentRoute: () => endpointRoute,
} as any);

const bodyRoute = bodyImport.update({
  id: '/body',
  path: '/body',
  getParentRoute: () => endpointRoute,
} as any);

const assertionsRoute = assertionsImport.update({
  id: '/assertions',
  path: '/assertions',
  getParentRoute: () => endpointRoute,
} as any);

const queryRoute = queryImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => endpointRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authorized': {
      id: '/_authorized';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof authorizedImport;
      parentRoute: typeof rootRoute;
    };
    '/login': {
      id: '/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof loginImport;
      parentRoute: typeof rootRoute;
    };
    '/_authorized/_dashboard': {
      id: '/_authorized/_dashboard';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof dashboardImport;
      parentRoute: typeof authorizedImport;
    };
    '/_authorized/_dashboard/': {
      id: '/_authorized/_dashboard/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof workspaceListImport;
      parentRoute: typeof dashboardImport;
    };
    '/_authorized/workspace/$workspaceIdCan': {
      id: '/_authorized/workspace/$workspaceIdCan';
      path: '/workspace/$workspaceIdCan';
      fullPath: '/workspace/$workspaceIdCan';
      preLoaderRoute: typeof workspaceLayoutImport;
      parentRoute: typeof authorizedImport;
    };
    '/_authorized/workspace/$workspaceIdCan/members': {
      id: '/_authorized/workspace/$workspaceIdCan/members';
      path: '/members';
      fullPath: '/workspace/$workspaceIdCan/members';
      preLoaderRoute: typeof workspaceMembersImport;
      parentRoute: typeof workspaceLayoutImport;
    };
    '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan': {
      id: '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan';
      path: '/endpoint/$endpointIdCan/example/$exampleIdCan';
      fullPath: '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan';
      preLoaderRoute: typeof endpointImport;
      parentRoute: typeof workspaceLayoutImport;
    };
    '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/': {
      id: '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/';
      path: '/';
      fullPath: '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/';
      preLoaderRoute: typeof queryImport;
      parentRoute: typeof endpointImport;
    };
    '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions': {
      id: '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions';
      path: '/assertions';
      fullPath: '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions';
      preLoaderRoute: typeof assertionsImport;
      parentRoute: typeof endpointImport;
    };
    '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body': {
      id: '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body';
      path: '/body';
      fullPath: '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body';
      preLoaderRoute: typeof bodyImport;
      parentRoute: typeof endpointImport;
    };
    '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers': {
      id: '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers';
      path: '/headers';
      fullPath: '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers';
      preLoaderRoute: typeof headersImport;
      parentRoute: typeof endpointImport;
    };
  }
}

// Create and export the route tree

interface dashboardRouteChildren {
  workspaceListRoute: typeof workspaceListRoute;
}

const dashboardRouteChildren: dashboardRouteChildren = {
  workspaceListRoute: workspaceListRoute,
};

const dashboardRouteWithChildren = dashboardRoute._addFileChildren(
  dashboardRouteChildren,
);

interface endpointRouteChildren {
  queryRoute: typeof queryRoute;
  assertionsRoute: typeof assertionsRoute;
  bodyRoute: typeof bodyRoute;
  headersRoute: typeof headersRoute;
}

const endpointRouteChildren: endpointRouteChildren = {
  queryRoute: queryRoute,
  assertionsRoute: assertionsRoute,
  bodyRoute: bodyRoute,
  headersRoute: headersRoute,
};

const endpointRouteWithChildren = endpointRoute._addFileChildren(
  endpointRouteChildren,
);

interface workspaceLayoutRouteChildren {
  workspaceMembersRoute: typeof workspaceMembersRoute;
  endpointRoute: typeof endpointRouteWithChildren;
}

const workspaceLayoutRouteChildren: workspaceLayoutRouteChildren = {
  workspaceMembersRoute: workspaceMembersRoute,
  endpointRoute: endpointRouteWithChildren,
};

const workspaceLayoutRouteWithChildren = workspaceLayoutRoute._addFileChildren(
  workspaceLayoutRouteChildren,
);

interface authorizedRouteChildren {
  dashboardRoute: typeof dashboardRouteWithChildren;
  workspaceLayoutRoute: typeof workspaceLayoutRouteWithChildren;
}

const authorizedRouteChildren: authorizedRouteChildren = {
  dashboardRoute: dashboardRouteWithChildren,
  workspaceLayoutRoute: workspaceLayoutRouteWithChildren,
};

const authorizedRouteWithChildren = authorizedRoute._addFileChildren(
  authorizedRouteChildren,
);

export interface FileRoutesByFullPath {
  '': typeof dashboardRouteWithChildren;
  '/login': typeof loginRoute;
  '/': typeof workspaceListRoute;
  '/workspace/$workspaceIdCan': typeof workspaceLayoutRouteWithChildren;
  '/workspace/$workspaceIdCan/members': typeof workspaceMembersRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan': typeof endpointRouteWithChildren;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/': typeof queryRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions': typeof assertionsRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body': typeof bodyRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers': typeof headersRoute;
}

export interface FileRoutesByTo {
  '': typeof authorizedRouteWithChildren;
  '/login': typeof loginRoute;
  '/': typeof workspaceListRoute;
  '/workspace/$workspaceIdCan': typeof workspaceLayoutRouteWithChildren;
  '/workspace/$workspaceIdCan/members': typeof workspaceMembersRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan': typeof queryRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions': typeof assertionsRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body': typeof bodyRoute;
  '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers': typeof headersRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/_authorized': typeof authorizedRouteWithChildren;
  '/login': typeof loginRoute;
  '/_authorized/_dashboard': typeof dashboardRouteWithChildren;
  '/_authorized/_dashboard/': typeof workspaceListRoute;
  '/_authorized/workspace/$workspaceIdCan': typeof workspaceLayoutRouteWithChildren;
  '/_authorized/workspace/$workspaceIdCan/members': typeof workspaceMembersRoute;
  '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan': typeof endpointRouteWithChildren;
  '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/': typeof queryRoute;
  '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions': typeof assertionsRoute;
  '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body': typeof bodyRoute;
  '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers': typeof headersRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ''
    | '/login'
    | '/'
    | '/workspace/$workspaceIdCan'
    | '/workspace/$workspaceIdCan/members'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | ''
    | '/login'
    | '/'
    | '/workspace/$workspaceIdCan'
    | '/workspace/$workspaceIdCan/members'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body'
    | '/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers';
  id:
    | '__root__'
    | '/_authorized'
    | '/login'
    | '/_authorized/_dashboard'
    | '/_authorized/_dashboard/'
    | '/_authorized/workspace/$workspaceIdCan'
    | '/_authorized/workspace/$workspaceIdCan/members'
    | '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan'
    | '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/'
    | '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions'
    | '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body'
    | '/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  authorizedRoute: typeof authorizedRouteWithChildren;
  loginRoute: typeof loginRoute;
}

const rootRouteChildren: RootRouteChildren = {
  authorizedRoute: authorizedRouteWithChildren,
  loginRoute: loginRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "root.tsx",
      "children": [
        "/_authorized",
        "/login"
      ]
    },
    "/_authorized": {
      "filePath": "authorized.tsx",
      "children": [
        "/_authorized/_dashboard",
        "/_authorized/workspace/$workspaceIdCan"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_authorized/_dashboard": {
      "filePath": "dashboard.tsx",
      "parent": "/_authorized",
      "children": [
        "/_authorized/_dashboard/"
      ]
    },
    "/_authorized/_dashboard/": {
      "filePath": "workspace-list.tsx",
      "parent": "/_authorized/_dashboard"
    },
    "/_authorized/workspace/$workspaceIdCan": {
      "filePath": "workspace-layout.tsx",
      "parent": "/_authorized",
      "children": [
        "/_authorized/workspace/$workspaceIdCan/members",
        "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan"
      ]
    },
    "/_authorized/workspace/$workspaceIdCan/members": {
      "filePath": "workspace-members.tsx",
      "parent": "/_authorized/workspace/$workspaceIdCan"
    },
    "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan": {
      "filePath": "endpoint.tsx",
      "parent": "/_authorized/workspace/$workspaceIdCan",
      "children": [
        "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/",
        "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions",
        "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body",
        "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers"
      ]
    },
    "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/": {
      "filePath": "query.tsx",
      "parent": "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan"
    },
    "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/assertions": {
      "filePath": "assertions.tsx",
      "parent": "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan"
    },
    "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/body": {
      "filePath": "body.tsx",
      "parent": "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan"
    },
    "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan/headers": {
      "filePath": "headers.tsx",
      "parent": "/_authorized/workspace/$workspaceIdCan/endpoint/$endpointIdCan/example/$exampleIdCan"
    }
  }
}
ROUTE_MANIFEST_END */
